import * as React from "react"
import styled from 'styled-components/macro'

const ColorsWrapper = styled.div`
  width: calc(100% - 50px);
  padding: 120px 25px 0;
  background-color: #FFFFFF;
`

const ColorsSection = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`

const ColorsTitle = styled.h1`
  text-align: center;
  font-size: 30px;
  font-weight: 600;
`

const ColorWrapper = styled.div`
  width: ${(props) => `calc(${props.width}% - 20px)`};
  height: 80px;
  text-align: center;
  margin: 10px;
  display: inline-block;

  @media (max-width: 800px) {
    width: ${(props) => props.width < 20 && `calc(${props.width * 2}% - 20px)`};
  }
`

const ColorTile = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 5px;
  height: 60px;
`

const ColorsSubtitle = styled.div`
  text-align: center;
  font-size: 25px;
  margin-top: 30px;
`

const ColorName = styled.div`
  font-weight: 600;
  margin-top: 7px;
  margin-bottom: 4px;
`

const Color = ({title, color, width}) => {
  return (
    <ColorWrapper width={width}>
      <ColorTile color={color}/>
      <ColorName>{title}</ColorName>
      <div>{color}</div>
    </ColorWrapper>
  )
}
 
const Colors = () => {
  const primaryColors = [{'Primary 1': '#3d3e54'}, {'Primary 2': '#4987d7'}, {'Primary 3': '#3f4056'}]
  const bgColors = [{'Bg 1': '#1e1c29'}, {'Bg 2': '#292938'}, {'Bg 3': '#2f2f40'}, {'Bg 4': '#40444F'}, {'Bg 5': '#565A69'}, {'Bg 6': '#6C7284'}]
  const otherColors = [{'Red 1': '#fc3b26'}, {'Green 1': '#8fc942'}, {'Yellow 1': '#f9a825'}, {'Blue 1': '#2172E5'}, {'Red 2': '#f44336'}, {'Green 2': '#68b04d'}, {'Yellow 2': '#F3841E'}, {'Blue 2': '#3a86f3'}]
  return (
    <ColorsWrapper>
      <ColorsSection>
        <ColorsTitle id='colors'>Colors</ColorsTitle>
        <ColorsSubtitle>Primary</ColorsSubtitle>
        <Color title={'Dolo'} color={'#292938'} width={100}/>
        {primaryColors.map(color => <Color title={Object.keys(color)} color={Object.values(color)} width={33.33}/>)}
        <ColorsSubtitle>Background / Grayscale</ColorsSubtitle>
        {bgColors.map(color => <Color title={Object.keys(color)} color={Object.values(color)} width={16.66}/>)}
        <ColorsSubtitle>Other</ColorsSubtitle>
        {otherColors.map(color => <Color title={Object.keys(color)} color={Object.values(color)} width={25}/>)}
      </ColorsSection>
    </ColorsWrapper>
  )
}

export default Colors
